import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    HOROSCOPE_LIST_REQUEST: null,
    HOROSCOPE_LIST_SUCCESS: null,
    HOROSCOPE_LIST_FAILURE: null,

    FIND_USER_HOROSCOPE_REQUEST: null,
    FIND_USER_HOROSCOPE_SUCCESS: null,
    FIND_USER_HOROSCOPE_FAILURE: null,

    SELECT_SINGLE_HOROSCOPE_REQUEST: null,

    SELECT_SINGLE_LIFE_EVENT_REQUEST: null,

    SELECT_HOROSCOPE_PLAN_REQUEST: null,

    USER_ACTIVE_HOROSCOPES_REQUEST: null,
    USER_ACTIVE_HOROSCOPES_SUCCESS: null,
    USER_ACTIVE_HOROSCOPES_FAILURE: null,

    CHECK_IF_LIVE_REQUEST: null,
    CHECK_IF_LIVE_SUCCESS: null,
    CHECK_IF_LIVE_FAILURE: null,

    USER_PREVIOUS_HOROSCOPES_REQUEST: null,
    USER_PREVIOUS_HOROSCOPES_SUCCESS: null,
    USER_PREVIOUS_HOROSCOPES_FAILURE: null,

    GET_NEW_SINGLE_HOROSCOPE_REQUEST: null,
    GET_NEW_SINGLE_HOROSCOPE_SUCCESS: null,
    GET_NEW_SINGLE_HOROSCOPE_FAILURE: null,

    SELECT_SELECTED_HOROSCOPE: null,
});

export const {
    horoscopeListRequest,
    horoscopeListSuccess,
    horoscopeListFailure,
} = createActions(
    constants.HOROSCOPE_LIST_REQUEST,
    constants.HOROSCOPE_LIST_SUCCESS,
    constants.HOROSCOPE_LIST_FAILURE,
);

export const {
    findUserHoroscopeRequest,
    findUserHoroscopeSuccess,
    findUserHoroscopeFailure,
} = createActions(
    constants.FIND_USER_HOROSCOPE_REQUEST,
    constants.FIND_USER_HOROSCOPE_SUCCESS,
    constants.FIND_USER_HOROSCOPE_FAILURE,
);

export const {
    selectSingleHoroscopeRequest,
} = createActions(
    constants.SELECT_SINGLE_HOROSCOPE_REQUEST,
);

export const {
    selectSelectedHoroscope,
} = createActions(
    constants.SELECT_SELECTED_HOROSCOPE,
);

export const {
    selectSingleLifeEventRequest,
} = createActions(
    constants.SELECT_SINGLE_LIFE_EVENT_REQUEST,
);

export const {
    selectHoroscopePlanRequest,
} = createActions(
    constants.SELECT_HOROSCOPE_PLAN_REQUEST,
);

export const {
    userActiveHoroscopesRequest,
    userActiveHoroscopesSuccess,
    userActiveHoroscopesFailure,
} = createActions(
    constants.USER_ACTIVE_HOROSCOPES_REQUEST,
    constants.USER_ACTIVE_HOROSCOPES_SUCCESS,
    constants.USER_ACTIVE_HOROSCOPES_FAILURE,
);

export const {
    checkIfLiveRequest,
    checkIfLiveSuccess,
    checkIfLiveFailure,
} = createActions(
    constants.CHECK_IF_LIVE_REQUEST,
    constants.CHECK_IF_LIVE_SUCCESS,
    constants.CHECK_IF_LIVE_FAILURE,
);

export const {
    userPreviousHoroscopesRequest,
    userPreviousHoroscopesSuccess,
    userPreviousHoroscopesFailure,
} = createActions(
    constants.USER_PREVIOUS_HOROSCOPES_REQUEST,
    constants.USER_PREVIOUS_HOROSCOPES_SUCCESS,
    constants.USER_PREVIOUS_HOROSCOPES_FAILURE,
);

export const {
    getNewSingleHoroscopeRequest,
    getNewSingleHoroscopeSuccess,
    getNewSingleHoroscopeFailure,
} = createActions(
    constants.GET_NEW_SINGLE_HOROSCOPE_REQUEST,
    constants.GET_NEW_SINGLE_HOROSCOPE_SUCCESS,
    constants.GET_NEW_SINGLE_HOROSCOPE_FAILURE,
);
