import React, { useState } from 'react';
import Slider from 'react-slick';
import propTypes from 'prop-types';
import navRightIcon from '../../assets/images/carouselRightNav.svg';
import navLeftIcon from '../../assets/images/carouselLeftNav.svg';
import navLeftDisabledIcon from '../../assets/images/carouselLeftNavDisabled.svg';
import navRightDisabledIcon from '../../assets/images/carouselRightNavDisabled.svg';
import './horoscopeCarousel.scss';

function NextArrow(props) {
    const { onClick, currentSlide, numberOfSlides } = props;
    return (
        <div onClick={onClick} className="carousel-right-nav-arrow">
            <img src={currentSlide < numberOfSlides ? navRightIcon : navRightDisabledIcon} alt="nav-right" />
        </div>
    );
}

NextArrow.propTypes = {
    onClick: propTypes.func,
    currentSlide: propTypes.number,
    numberOfSlides: propTypes.number,
};

NextArrow.defaultProps = {
    onClick: () => {},
    currentSlide: 1,
    numberOfSlides: 6,
};

function PrevArrow(props) {
    const { onClick, currentSlide } = props;
    return (
        <div className="carousel-left-nav-arrow" onClick={onClick}>
            <img src={currentSlide > 0 ? navLeftIcon : navLeftDisabledIcon} alt="nav-left" />
        </div>
    );
}

PrevArrow.propTypes = {
    currentSlide: propTypes.number,
    onClick: propTypes.func,
};

PrevArrow.defaultProps = {
    currentSlide: 1,
    onClick: () => {},
};

const CarouselCard = ({ title, onClick, activeSlide, cardId }) => {
    const isActive = activeSlide === cardId;
    return (
        <div className="carousel-card-container" onClick={onClick}>
            <div className={`content-wrapper ${isActive ? 'content-active' : ''}`}>
                <p className="title">{title}</p>
            </div>
        </div>
    );
};

CarouselCard.propTypes = {
    title: propTypes.string,
    onClick: propTypes.func,
    activeSlide: propTypes.number,
    cardId: propTypes.number,
};

CarouselCard.defaultProps = {
    title: '',
    onClick: () => {},
    activeSlide: 1,
    cardId: 1,
};

const HoroscopeCarousel = ({ data, activeSlide }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const getSlidesToShow = () => {
        if (window.innerWidth < 768 || data.length <= 1) {
            return 1;
        }
        if (window.innerWidth < 1024 || data.length <= 2) {
            return 2;
        }
        return 3;
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        nextArrow: <NextArrow currentSlide={currentSlide} numberOfSlides={data.length - getSlidesToShow()} />,
        prevArrow: <PrevArrow currentSlide={currentSlide} />,
        initialSlide: data.length - getSlidesToShow(),
        afterChange: (current) => {
            setCurrentSlide(current);
        },
    };

    return (
        <Slider {...settings}>
            {data.map((item) => (
                <CarouselCard
                    key={item.id}
                    title={item.title}
                    onClick={item.onClick}
                    activeSlide={activeSlide}
                    cardId={item.id} />
            ))}
        </Slider>
    );
};

HoroscopeCarousel.propTypes = {
    data: propTypes.array,
    activeSlide: propTypes.number,
};

HoroscopeCarousel.defaultProps = {
    data: [],
    activeSlide: 1,
};

export default HoroscopeCarousel;
