/* global Stripe */
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { message, notification } from 'antd';

import { fetchApi, fetchApiAuth } from '../utils/api';
import createCardToken from '../utils/stripe';
import history from '../utils/history';

import {
    getPaymentPlansSuccess,
    getPaymentPlansFailure,

    addPaymentCardSuccess,
    addPaymentCardFailure,

    savePaymentCardRequest,
    savePaymentCardSuccess,
    savePaymentCardFailure,

    buySubscriptionRequest,
    buySubscriptionSuccess,
    buySubscriptionFailure,

    buyConsultationRequest,
    buyConsultationSuccess,
    buyConsultationFailure,

    getUserHistoricSubscriptionsSuccess,
    getUserHistoricSubscriptionsFailure,

    getPaymentCardsSuccess,
    getPaymentCardsFailure,

    applyCouponCodeSuccess,
    applyCouponCodeFailure,

    cancelSubscriptionSuccess,
    cancelSubscriptionFailure,

    buyOneOffRequest,
    buyOneOffSuccess,
    buyOneOffFailure,

    buyLifeEventRequest,
    buyLifeEventSuccess,
    buyLifeEventFailure,
} from './paymentActions';

import {
    saveUserDataRequest,
} from '../user/userActions';

const {
    GET_PAYMENT_PLANS_REQUEST,
    ADD_PAYMENT_CARD_REQUEST,
    SAVE_PAYMENT_CARD_REQUEST,
    BUY_SUBSCRIPTION_REQUEST,
    BUY_CONSULTATION_REQUEST,
    GET_USER_HISTORIC_SUBSCRIPTIONS_REQUEST,
    GET_PAYMENT_CARDS_REQUEST,
    APPLY_COUPON_CODE_REQUEST,
    CANCEL_SUBSCRIPTION_REQUEST,
    BUY_ONE_OFF_REQUEST,
    BUY_LIFE_EVENT_REQUEST,
} = require('./paymentActions').constants;

// const { REACT_APP_URL } = process.env;

function* getPaymentPlans() {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/payment-plan/plans',
        });

        yield put(getPaymentPlansSuccess(response));
    } catch (e) {
        // message.error(e.response ? e.response.data.message : e);
        yield put(getPaymentPlansFailure(e.response ? e.response.data.message : e));
    }
}

function* addPaymentCard(action) {
    try {
        const response = yield call(createCardToken, action.payload);
        yield put(addPaymentCardSuccess(response));

        // add card to customer account
        if (action.payload.type === 'oneOffPurchase' || action.payload.type === 'oneOffPurchaseMobile') {
            yield put(savePaymentCardRequest({
                token: response.data.id,
                card: response.data.card,
                user_id: action?.payload?.user_id,
                payment_type: action.payload.payment_type,
                payment_plan_id: action.payload?.payment_plan_id,
                horoscope_id: action.payload?.horoscope_id,
                horoscope_data_id: action.payload?.horoscope_data_id,
                selectedLang: action.payload?.selectedLang,
                type: action.payload.type,
            }));
        } else if (action.payload.type === 'lifeEvent') {
            yield put(savePaymentCardRequest({
                token: response.data.id,
                card: response.data.card,
                user_id: action?.payload?.user_id,
                horoscopeDataParts: action?.payload?.horoscopeDataParts,
                selectedLang: action.payload?.selectedLang,
                type: action.payload.type,
            }));
        } else if (action.payload.type === 'horoscope') {
            yield put(savePaymentCardRequest({
                token: response.data.id,
                card: response.data.card,
                user_id: action?.payload?.user_id,
                payment_plan_id: action.payload?.payment_plan_id,
                horoscope_id: action.payload?.horoscope_id,
                selectedLang: action.payload?.selectedLang,
                type: action.payload.type,
            }));
        } else {
            yield put(savePaymentCardRequest({
                token: response.data.id,
                card: response.data.card,
                isRegistering: action.payload.isRegistering,
                paymentPlanId: action.payload.paymentPlanId,
                horoscope_id: action.payload.horoscope_id,
                type: action.payload.type,
                user_id: action.payload.user_id,
                consultation_id: action.payload.consultation_id,
                payment_type: action.payload.payment_type,
                questions: action.payload.questions,
                extra_questions: action.payload.extra_questions,
                payment_plan_id: action.payload.payment_plan_id,
                phone_number: action.payload.phone_number,
            }));
        }
    } catch (e) {
        message.error(e.response ? `12: ${e.response.data.error.message}` : `12: ${e}`);
        yield put(addPaymentCardFailure(e.response ? e.response.data.error.message : e));
    }
}

async function authenticatePurchase(clientSecret) {
    const secretKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const stripe = Stripe(secretKey);
    const { paymentIntent, error } = await stripe.handleCardPayment(clientSecret);
    if (error) {
        return { success: false, data: error };
    }
    if (paymentIntent.status !== 'succeeded') {
        return { success: false, data: 'Additional security failed' };
    }
    return { success: true, data: paymentIntent };
}

function* buyLifeEvent(action) {
    const notifyPaymentError = () => {
        const langError = action?.payload?.selectedLang === 2 ? 'असफल' : 'Error';
        const langDescription = action?.payload?.selectedLang === 2 ? 'भुगतान अधूरा रह गया' : 'Payment was incomplete';
        notification.error({ message: langError, description: langDescription });
    };

    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'payment-plan/buy-life-event',
            body: action.payload,
        });

        if (response?.data?.payment_type === 'secure') {
            const { client_secret, userLifeEventId } = response?.data;
            const auth = yield call(authenticatePurchase, client_secret);
            if (!auth.success) {
                notifyPaymentError();
                yield put(buyLifeEventFailure(auth.data));
            } else {
                const authResponse = yield call(fetchApiAuth, {
                    method: 'POST',
                    url: 'payment-plan/authorize-life-event-purchase',
                    body: { client_secret, userLifeEventId },
                });

                if (authResponse?.data?.payment_type === 'succeeded') {
                    yield put(buyLifeEventSuccess(response));
                    // Trigger the reset of lifeEvents in Redux state
                    yield put({ type: 'ADD_LIFE_EVENT_REQUEST', payload: 'reset' });

                    if (action?.payload?.payment_type === 'web') {
                        window.location.replace('prakashastrology://lifeEvents/true');
                    } else {
                        history.replace('/life-event-confirmation');
                    }

                } else {
                    notifyPaymentError();
                    yield put(buyLifeEventFailure(response));
                }
            }
        } else if (response?.data?.payment_type === 'succeeded') {
            yield put(buyLifeEventSuccess(response));
            // Trigger the reset of lifeEvents in Redux state
            yield put({ type: 'ADD_LIFE_EVENT_REQUEST', payload: 'reset' });

            if (action?.payload?.payment_type === 'web') {
                window.location.replace('prakashastrology://lifeEvents/true');
            } else {
                history.replace('/life-event-confirmation');
            }

        } else {
            notifyPaymentError();
            yield put(buyLifeEventFailure(response));
        }

    } catch (e) {
        // alertify.error(e.response ? e.response.data.message : e);
        yield put(buyLifeEventFailure(e.response ? e.response.data.message : e));
        const warningMessage = action?.payload?.lanuage === 2 ? 'गलती' : 'Error';
        const description = action?.payload?.lanuage === 2 ? 'भुगतान सफल नहीं हुआ. कृपया पुन: प्रयास करें' : 'The payment was not successful. Please try again';
        notification.warning({ message: warningMessage, description });
    }
}

function* buyOneOff(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'payment-plan/one-off-horoscope-purchase',
            body: action.payload,
        });

        const langSuccess = action?.payload?.selectedLang === 2 ? 'सफल' : 'Success';

        yield put(buyOneOffSuccess(response));
        notification.success({ message: langSuccess, description: response?.data?.msg });
        history.replace('/dashboard');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(buyOneOffFailure(e.response ? e.response.data.message : e));
    }
}

function* savePaymentCard(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'payment-plan/save-payment-card',
            body: action.payload,
        });

        yield put(savePaymentCardSuccess(response));

        if (action.payload.type === 'consultation') {
            yield put(buyConsultationRequest(action.payload));
        } else if (action.payload.type === 'oneOffPurchase' || action.payload.type === 'oneOffPurchaseMobile') {
            // if type is oneOffPurchase then buy one off horoscope
            const oneOffBuyPayload = {
                payment_plan_id: action.payload?.payment_plan_id,
                card_id: action.payload?.card?.id,
                horoscope_id: action.payload?.horoscope_id,
                horoscope_data_id: action.payload?.horoscope_data_id,
                selectedLang: action.payload?.selectedLang,
                type: action.payload?.type,
            };

            yield put(buyOneOffRequest(oneOffBuyPayload));
        } else if (action.payload.type === 'lifeEvent') {
            yield put(buyLifeEventRequest(action.payload));
        } else if (action.payload.type === 'horoscope') {
            yield put(buySubscriptionRequest({
                payment_plan_id: action.payload.payment_plan_id,
                card_id: action.payload.card.id,
                horoscope_id: action.payload.horoscope_id,
            }));
        } else {
            yield put(buySubscriptionRequest({
                payment_plan_id: action.payload.paymentPlanId,
                card_id: action.payload.card.id,
                horoscope_id: action.payload.horoscope_id,
            }));
        }

        notification.success({ message: 'Success', description: 'You have succesfully added your payment card' });
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(savePaymentCardFailure(e.response ? e.response.data.message : e));
    }
}

async function authenticateUserSubscription(clientSecret, user_payment_plan_id) {
    const secretKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const stripe = Stripe(secretKey);

    const { paymentIntent, error } = await stripe.handleCardPayment(clientSecret);

    if (error) {
        return { success: false, data: null };
    }

    if (paymentIntent.status !== 'succeeded') {
        return { success: false, data: 'Additional security failed' };
    }

    try {
        const response = await fetchApiAuth({
            method: 'POST',
            url: 'payment-plan/complete-subscription',
            body: { user_payment_plan_id },
        });
        return { success: true, data: response };
    } catch (e) {
        return { success: false, data: e };
    }
}

function* buyConsultation(action) {
    const notifyPaymentError = () => {
        const langError = action?.payload?.selectedLang === 2 ? 'असफल' : 'Error';
        const langDescription = action?.payload?.selectedLang === 2 ? 'भुगतान अधूरा रह गया' : 'Payment was incomplete';
        notification.error({ message: langError, description: langDescription });
    };

    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'payment-plan/buy-consultation',
            body: action.payload,
        });

        if (response?.data?.payment_type === 'secure') {
            notification.warning({ message: 'Warning', description: 'Card requires additional authentication' });
            const { client_secret, userConsultationId } = response?.data;
            const auth = yield call(authenticatePurchase, client_secret);
            if (!auth.success) {
                notifyPaymentError();
                yield put(buyConsultationFailure(auth.data));
            } else {
                const authResponse = yield call(fetchApiAuth, {
                    method: 'POST',
                    url: 'payment-plan/authorize-consultation-purchase',
                    body: { userConsultationId },
                });

                if (authResponse?.data?.payment_type === 'succeeded') {
                    notification.success({ message: 'Success', description: 'Additional security succeeded. You have successfully bought the consultation' });
                    yield put(buyConsultationSuccess(response));

                    if (action.payload.payment_type === 'web') {
                        window.location.replace('prakashastrology://consultations/true');
                    } else {
                        history.replace('/dashboard');
                    }

                } else {
                    notifyPaymentError();
                    yield put(buyConsultationFailure(response));
                }
            }

        } else if (response?.data?.payment_type === 'succeeded') {
            notification.success({ message: 'Success', description: 'You have successfully bought the consultation' });
            yield put(buyConsultationSuccess(response));

            if (action.payload.payment_type === 'web') {
                window.location.replace('prakashastrology://consultations/true');
            } else {
                history.replace('/dashboard');
            }

        } else {
            notifyPaymentError();
            yield put(buyConsultationFailure(response));
        }

    } catch (e) {
        const warningMessage = action?.payload?.lanuage === 2 ? 'गलती' : 'Error';
        const description = action?.payload?.lanuage === 2 ? 'भुगतान सफल नहीं हुआ. कृपया पुन: प्रयास करें' : 'The payment was not successful. Please try again';
        notification.warning({ message: warningMessage, description });
        yield put(buyConsultationFailure(e.response ? e.response.data.message : e));
    }
}

function* buySubscription(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'payment-plan/buy-subscription',
            body: action.payload,
        });

        const langSuccess = action?.payload?.selectedLang === 2 ? 'सफल' : 'Success';
        const langWarning = action?.payload?.selectedLang === 2 ? 'चेतावनी' : 'Warning';

        if (response.data.type === 'require_3d') {
            notification.warning({ message: langWarning, description: 'Card requires additional authentication' });
            const { success, data } = yield call(authenticateUserSubscription, response.data.client_secret, response.data.user_payment_plan_id);

            if (success) {
                notification.success({ message: langSuccess, description: 'Additional security succeeded. You have successfully bought the subscription' });
                yield put(buySubscriptionSuccess(data));
                yield put(saveUserDataRequest(data));
                history.replace('/dashboard');
            } else {
                notification.warning({ message: langWarning, description: 'Additional security failed' });
                yield put(buySubscriptionFailure(data ? data.response.data.message : data));
            }
        } else {
            notification.success({ message: langSuccess, description: response?.data?.msg || 'You have successfully bought the subscription' });
            yield put(buySubscriptionSuccess(response));
            yield put(saveUserDataRequest(response));
            history.replace('/dashboard');
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(buySubscriptionFailure(e.response ? e.response.data.message : e));
    }
}

function* getUserHistoricSubscriptions() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'payment-plan/get-user-historic-subscriptions',
        });

        yield put(getUserHistoricSubscriptionsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e?.message);
        yield put(getUserHistoricSubscriptionsFailure(e.response ? e.response.data.message : e?.message));
    }
}

function* getPaymentCards() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'payment-plan/get-payment-cards',
        });

        yield put(getPaymentCardsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e?.message);
        yield put(getPaymentCardsFailure(e.response ? e.response.data.message : e));
    }
}

function* applyCouponCode(payload) {

    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `payment-plan/apply-discount-code?code=${payload?.payload?.promoCode}&selectedPlan=${payload?.payload?.selectedPlan}&selectedlang=${payload?.payload?.selectedLang}`,
        });

        const langSuccess = payload?.payload?.selectedLang === 2 ? 'सफल' : 'Success';
        const langError = payload?.payload?.selectedLang === 2 ? 'असफल' : 'Error';

        if (response.data.status === 200) {

            notification.success({ message: langSuccess, description: response?.data?.msg });
            history.replace({
                pathname: '/order-confirm',
                appliedCouponCodeData: response?.data?.coupon,
            });
        } else if (response.data.status === 404) {
            notification.error({ message: langError, description: response?.data?.msg });
        }
        yield put(applyCouponCodeSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(applyCouponCodeFailure(e.response ? e.response.data.message : e));
    }
}

function* cancelSubscription(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'payment-plan/cancel-subscription',
            body: action.payload,
        });

        yield put(cancelSubscriptionSuccess(response));

    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(cancelSubscriptionFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* authSaga() {
    yield* [
        takeLatest(GET_PAYMENT_PLANS_REQUEST, getPaymentPlans),
        takeLatest(ADD_PAYMENT_CARD_REQUEST, addPaymentCard),
        takeLatest(SAVE_PAYMENT_CARD_REQUEST, savePaymentCard),
        takeLatest(BUY_SUBSCRIPTION_REQUEST, buySubscription),
        takeLatest(BUY_CONSULTATION_REQUEST, buyConsultation),
        takeLatest(GET_USER_HISTORIC_SUBSCRIPTIONS_REQUEST, getUserHistoricSubscriptions),
        takeEvery(GET_PAYMENT_CARDS_REQUEST, getPaymentCards),
        takeEvery(APPLY_COUPON_CODE_REQUEST, applyCouponCode),
        takeEvery(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription),
        takeEvery(BUY_ONE_OFF_REQUEST, buyOneOff),
        takeEvery(BUY_LIFE_EVENT_REQUEST, buyLifeEvent),
    ];
}
