import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import moment from 'moment';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import history from '../../core/utils/history';
import './oneoffconfirmation.scss';
import leftAngle from '../../assets/images/left-angle.svg';
import { saveOneOffPaymentRequest } from '../../core/payment/paymentActions';

const OneOffConfirmation = () => {

    const { singleHoroscope, isFetching } = useSelector(state => state.horoscope);
    const location = useLocation();
    const { language } = useLanguage();
    const dispatch = useDispatch();
    const { horoscopeDataId, horoscopeType } = location?.state || {};

    // if no location state then redirect to dashboard
    useEffect(() => {
        if (!location?.state) {
            history.push('/dashboard');
        }
    }, [location?.state]);

    if (!location?.state) {
        return null;
    }

    const horoscopePaymentPlan = singleHoroscope?.horoscopePaymentPlans?.find(plan => plan.payment_plan_id.toString() === horoscopeType.toString());
    const horoscopeData = singleHoroscope?.horoscopeData?.find(horoscope => horoscope.id.toString() === horoscopeDataId.toString());
    const horoscopeTranslation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);

    const backBtn = () => (
        <button style={{ backgroundColor: 'transparent', border: 0 }}
            onClick={() => history.goBack()}
            type="button">
            <img src={leftAngle} alt="go back" />
        </button>
    );

    const gethoroscopePeriodType = () => {
        if (singleHoroscope?.type === '1') {
            return getTranslation(language, 'horoscopes_listing_screen_tab_weekly_title', 'Weekly');
        } if (horoscopeData?.type === '2') {
            return getTranslation(language, 'horoscopes_listing_screen_tab_monthly_title', 'Monthly');
        } if (horoscopeData?.type === '3') {
            return getTranslation(language, 'horoscopes_listing_screen_tab_yearly_title', 'Yearly');
        }
        return '';
    };

    const getHoroscopeDate = () => {
        if (!horoscopeData?.date || !moment(horoscopeData.date).isValid()) {
            return '';
        }

        if (horoscopeData?.type === '1') {
            return `${moment(horoscopeData?.date).format('MMMM DD')} - ${moment(horoscopeData?.date).add(6, 'day').format('MMMM DD')}`;
        }
        if (horoscopeData?.type === '2') {
            return `${moment(horoscopeData?.date).format('MMMM YYYY')}`;
        }
        return '';
    };

    const buyOneOffPlan = () => {
        if (horoscopePaymentPlan && horoscopeData) {
            dispatch(saveOneOffPaymentRequest({ horoscopeDataId, horoscopeType }));
            history.push({ pathname: '/enter-payment-details', state: { type: 'oneOffPurchase', paymentPlanId: horoscopePaymentPlan?.payment_plan_id } });
        }
    };

    return (
        <Main
            title={<div>Profile - Prakash Astrologer</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            backLink={backBtn()}
            menuWidth={350}
            menuPlacement="right"
            floatingHeader
            displayRightMenu={false}
            showFooter={false}>
            <div className="oneoffconfirmation-confirmation">
                {isFetching
                    ? (
                        <div className="spinner-style">
                            <Spin size="large" />
                        </div>
                    )
                    : (
                        <>
                            <h2 className="c-page-heading">{getTranslation(language, 'one_off_confirmation_title', 'Buy One Off Horoscope')}</h2>
                            <div className="c-description-container">
                                <p className="c-title">
                                    {horoscopeTranslation?.name}
                                </p>
                                <p className="c-sub-title">
                                    {`${gethoroscopePeriodType()} ${getTranslation(language, 'horoscope', 'Horoscope')}`}: {getHoroscopeDate()}
                                </p>
                                <p className="c-price">
                                    {getTranslation(language, 'subscription_settings_screen_cost_title', 'Price')}: £{horoscopePaymentPlan?.one_off_price}
                                </p>
                                <div className="c-line" />
                                <p className="c-description">
                                    {getTranslation(language, 'one_off_confirmation',
                                        'You will have access to the horoscope'
                        + 'through the app while available. '
                        + 'Also, you will receive a PDF file via email with all information.')}
                                </p>

                                <div className="c-buy-now">
                                    <button className="c-buy-button" type="button" onClick={() => buyOneOffPlan()}>
                                        {getTranslation(language, 'buy_now', 'Buy Now')}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </Main>
    );
};

export default OneOffConfirmation;
