import { findIndex } from 'underscore';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';

const getSelectedTranslation = (translations, selectedLanguageId = 1) => {
    let translation = null;
    if (translations) {
        const translationIndex = findIndex(translations, (val) => val.language_id === selectedLanguageId);
        if (translationIndex !== -1) {
            translation = translations[translationIndex];
        }
    }

    return translation;
};

const getPaymentPeriod = (payload) => {

    let period = 'Unknown';
    const periodType = parseInt(payload, 10);

    if (periodType === 1) {
        period = 'per week';
    }

    if (periodType === 2) {
        period = 'per month';
    }

    if (periodType === 3) {
        period = 'per year';
    }

    return period;
};

const getPeriodCycleString = (cycle) => {
    if (+cycle === 1) {
        return 'Weekly';
    } if (+cycle === 2) {
        return 'Yearly';
    }
    return 'Monthly';
};

const getSelectedPaymentPlan = (singleHoroscope) => {
    let result = '';

    if (+singleHoroscope?.current_period?.cycle === 1) {
        result = singleHoroscope?.horoscopePaymentPlans.find(plan => plan.payment_plan_id === 1);
    } if (+singleHoroscope?.current_period?.cycle === 2) {
        result = singleHoroscope?.horoscopePaymentPlans.find(plan => plan.payment_plan_id === 2);
    } if (+singleHoroscope?.current_period?.cycle === 3) {
        result = singleHoroscope?.horoscopePaymentPlans.find(plan => plan.payment_plan_id === 3);
    }
    return result;
};

const getTranslation = (language, translationKey, defaultText) => {
    if (language && language.selectedLanguage && language.selectedLanguage.systemValues && language.selectedLanguage.systemValues[translationKey]) {
        return language.selectedLanguage.systemValues[translationKey];
    }

    return defaultText;
};

export const convertToTranslationIndex = (data) => String(data).toLowerCase().split(' ').join('_');

const getHoroscopeDataRange = (singleHoroscope) => {
    if (singleHoroscope?.current_period?.cycle === 1) {
        return (
            `${singleHoroscope?.date ? moment(singleHoroscope?.date).format('MMMM D ') : ''} - ${singleHoroscope?.date ? moment(singleHoroscope?.date).add(6, 'days').format('MMMM D ') : ''}`);
    } if (singleHoroscope?.current_period?.cycle === 2) {
        return (
            singleHoroscope?.date ? moment(singleHoroscope?.date).format('MMMM') : ''
        );
    } if (singleHoroscope?.current_period?.cycle === 3) {
        return (
            singleHoroscope?.date ? moment(singleHoroscope?.date).format('YYYY') : ''
        );
    }
    return '';
};

const getHoroscopeFullDataRange = (singleHoroscope) => {
    if (singleHoroscope?.current_period?.cycle === 3) {
        return (
            singleHoroscope?.date ? `${moment(singleHoroscope?.current_period.from).format('MMMM D')} - ${moment(singleHoroscope?.current_period.to).format('MMMM D')}` : ''
        );
    }
    return '';
};

const getHoroscopeData = (singleHoroscope) => (
    `${singleHoroscope?.date ? moment(singleHoroscope?.current_period?.from).format('MMMM D ') : ''} - ${singleHoroscope?.date ? moment(singleHoroscope?.current_period?.to).format('MMMM D ') : ''}`);

const convertQuestionToId = (question) => {
    let final = question;
    let i = 0; const
        strLength = question.length;
    for (i; i < strLength; i++) {
        final = final.replace(' ', '_');
    }

    return final.toLowerCase();
};

// Function to convert a string to a URL
const convertToUrl = (str) => {
    // Replace spaces with hyphens
    const url = str.trim().replace(/\s+/g, '-').toLowerCase();
    return url;
};

const getOneOffPrice = (singleHoroscope) => {
    let result = '';

    if (+singleHoroscope?.current_period?.cycle === 1) {
        result = singleHoroscope?.horoscopePaymentPlans.find(plan => plan.payment_plan_id === 1);
    } if (+singleHoroscope?.current_period?.cycle === 2) {
        result = singleHoroscope?.horoscopePaymentPlans.find(plan => plan.payment_plan_id === 3);
    } if (+singleHoroscope?.current_period?.cycle === 3) {
        result = singleHoroscope?.horoscopePaymentPlans.find(plan => plan.payment_plan_id === 2);
    }
    return result?.one_off_price;
};

export {
    // Constants
    DATE_FORMAT,
    DISPLAY_DATE_FORMAT,
    getSelectedTranslation,
    getPaymentPeriod,
    getTranslation,
    getHoroscopeDataRange,
    getHoroscopeFullDataRange,
    convertQuestionToId,
    convertToUrl,
    getHoroscopeData,
    getOneOffPrice,
    getPeriodCycleString,
    getSelectedPaymentPlan,
    // Functions
};
